import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useContext } from 'react';

const StrapiContext = createContext();

export const useStrapiContext = () => {
  return useContext(StrapiContext);
};

export const StrapiProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query GlobalQuery {
      strapiGlobal {
        companyName
        supportEmail
        mainPhone
        businessHours
        address {
          street
          city
          state
          zip
        }
        googleAnalyticsId
        globalSeo {
          metaTitle
          metaDescription
          keywords
          metaRobots
          metaViewport
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        logoAlt {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      allStrapiFile {
        nodes {
          name
          alternativeText
          caption
          url
          localFile {
            base
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);

  return (
    <StrapiContext.Provider value={data}>{children}</StrapiContext.Provider>
  );
};
